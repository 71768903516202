<template>
<div>
	<div class="wrapper">
		<div id="main_content">
			<section class="no_page_content centered_content content_rows full_height">
				<div class="no_page_image">
					<img src="@/assets/images/404_image.jpg">
				</div>
				<p>La página que estas buscando no esta disponible.</p>
				<router-link to="/" class="btn dark big register_btn next down mt20"><span>Volver a inicio</span></router-link>
			</section>
        </div>
	</div>
    </div>
</template>

<script>
export default {
    name: 'Forbiden'
}
</script>